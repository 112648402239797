
import React from "react";
import cross from "./assets/cross-out.png";
import "./display.css";


function Display(props) {
    const display = React.useRef(null);

    function emitCloseEvent() {
        const closedClass = "display__content display__content--closed";
        const hasReference = display && display.current;

        if (hasReference) {
            display.current.setAttribute("class", closedClass);

            setTimeout(props.onClose, 600);
        }
    }

    return (
        <div className="display">
            <div className="display__content" ref={display}>
                {props.children}
                <div className="display__icon-box" onClick={emitCloseEvent}>
                    <img src={cross} alt="Close" className="display__icon"/>
                </div>
            </div>
        </div>
    );
}


export default Display;