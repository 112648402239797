import React from 'react';
import { Helmet } from "react-helmet"
import {useTranslation} from "react-i18next";
import Navigation from "../components/Navigation";
import SpreadNavigation from "../components/SpreadNavigation";
import TranslatedText from "../components/TranslatedText";
import TranslationSwitch from "../components/TranslationSwitch";
import Hero from "../scenes/Hero";
import Display from "../components/Display";
import film from "./assets/film.svg";
import about from "./assets/about-us.svg";
import news from "./assets/news.svg";
import contact from "./assets/phone.svg";
import franceFlag from "./assets/france.png";
import greatBritainFlag from "./assets/great-britain.png";
import './app.css';

const languages = [
    {value: "fr", icon: franceFlag},
    {value: "en", icon: greatBritainFlag},
];

const componentMap = {
    films: React.lazy(() => import("../scenes/Film")),
    news: React.lazy(() => import("../scenes/News")),
    about: React.lazy(() => import("../scenes/About")),
    contact: React.lazy(() => import("../scenes/Contact")),
    commissioningEditor: React.lazy(() => import("../scenes/CommissioningEditor")),
};

const FallBack = () => (
    <div
        style={{
            height: "4rem",
            width: "4rem",
            backgroundColor: "#333"
        }}
    />
);

function App() {
    const {t, i18n} = useTranslation();
    const changeLanguage = lng => i18n.changeLanguage(lng);
    const [componentsToDisplay, setComponentsToDisplay] = React.useState([]);
    const navigationLinks = [
        {text: <TranslatedText translationKey="navigation.film"/>, component: componentMap.films, icon: film},
        {text: <TranslatedText translationKey="navigation.news"/>, component: componentMap.news, icon: news},
        {text: <TranslatedText translationKey="navigation.aboutUs"/>, component: componentMap.about, icon: about},
        {text: <TranslatedText translationKey="navigation.contactUs"/>, component: componentMap.contact, icon: contact},
    ];

    return (
        <div className="app">
            <Helmet>
                <meta
                    name="description"
                    content={t("meta.description")}
                />
            </Helmet>
            <div className="app__main">
                <Hero />
                {componentsToDisplay.map(({id, component: ComponentToDisplay}) => (
                    <div
                        key={id}
                        className="app__display"
                    >
                        <Display
                            key={id}
                            onClose={() => setComponentsToDisplay(componentsToDisplay.filter(map => map.id !== id))}
                        >
                            <div className="app__content">
                                <React.Suspense fallback={<FallBack/>}>
                                    <ComponentToDisplay
                                        addComponentToDisplay={component => setComponentsToDisplay([
                                            ...componentsToDisplay,
                                            {id: componentsToDisplay.length, component},
                                        ])}
                                    />
                                </React.Suspense>
                            </div>
                        </Display>
                    </div>
                ))}
            </div>
            <div className="app__navigation">
                <div className="app__navigation__desktop">
                    <Navigation links={navigationLinks} handleNavigation={target => setComponentsToDisplay([{
                        id: `${Math.random() + componentsToDisplay.length}`,
                        component: target.component
                    }])}/>
                </div>
                <div className="app__navigation__mobile">
                    <SpreadNavigation links={navigationLinks} handleNavigation={target => setComponentsToDisplay([{
                        id: `${Math.random() + componentsToDisplay.length}`,
                        component: target.component
                    }])}/>
                </div>
            </div>
            <div className="app__translation-switch">
                <TranslationSwitch
                    languages={languages}
                    handleLanguageChoice={changeLanguage}
                />
            </div>
        </div>
    );
}

export default App;
