import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import XHR from "i18next-xhr-backend";
import { initReactI18next } from "react-i18next";

export function load() {
    return i18n
        .use(LanguageDetector)
        .use(initReactI18next)
        .use(XHR)
        .init({
            fallbackLng: "en",
            debug: true,
            ns: ["translations"],
            defaultNS: "translations",
            loadPath: "/content/{{lng}}/{{ns}}.json",
            addPath: "content/add/{{lng}}/{{ns}}",
            keySeparator: ".",
            returnObjects: true,
            interpolation: {
                escapeValue: false
            }
        });
}