
import React from "react";
import controller from "./assets/controller.svg";
import "./spreadNavigation.css";


function SpreadNavigation({ links, handleNavigation }) {
    const [isSpread, setSpreadState] = React.useState(false);
    const linksFirstHalf = links.slice(0, links.length / 2);
    const linksSecondHalf = links.slice(links.length / 2);

    return (
        <div
            className={`spread-navigation ${!isSpread ? "spread-navigation--minimized" : ""}`}
            onClick={!isSpread ? () => setSpreadState(!isSpread) : null}
        >
            <div className="spread-navigation__wrapper">
                {linksFirstHalf.map((link, index) => (
                    <div key={index} className="spread-navigation__link" onClick={() => handleNavigation(link)}>
                        <svg className="spread-navigation__icon">
                            <use xlinkHref={`${link.icon}#Capa_1`} />
                        </svg>
                    </div>
                ))}
            </div>
            <div
                className="spread-navigation__spread-button"
                onClick={isSpread ? () => setSpreadState(!isSpread) : null}
            >
                <img
                    src={controller}
                    alt="Spread Button"
                    className="spread-navigation__spread-button__icon"
                />
            </div>
            <div className="spread-navigation__wrapper">
                {linksSecondHalf.map((link, index) => (
                    <div key={index} className="spread-navigation__link" onClick={() => handleNavigation(link)}>
                        <svg className="spread-navigation__icon">
                            <use xlinkHref={`${link.icon}#Capa_1`} />
                        </svg>
                    </div>
                ))}
            </div>
        </div>
    )
}


export default SpreadNavigation;