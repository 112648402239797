
import React from "react";
import { Trans } from "react-i18next";


function TranslatedText({ translationKey }) {
    return <Trans i18nKey={translationKey} />
}


export default TranslatedText;