import React from "react";
import "./navigation.css";


function Navigation({ links, handleNavigation }) {
    return (
        <ul className="navigation">
            {links.map((link, index) => (
                <li
                    key={index}
                    className="navigation__item"
                    onClick={() => handleNavigation(link)}
                >
                    <p className="navigation__text">{link.text}</p>
                </li>
            ))}
        </ul>
    )
}


export default Navigation;