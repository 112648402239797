import "core-js/stable";
import "regenerator-runtime/runtime";
import React from 'react';
import ReactDOM from 'react-dom';
import {load} from "./translation";
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';

async function mountApp() {
    await load();

    ReactDOM.render(<App />, document.getElementById('root'));
}

mountApp();

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
