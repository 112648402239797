import React from "react";
import {Carousel} from "antd";
import TranslatedObject from "../../components/TranslatedObject";
import "./hero.css";


function Hero() {
    return (
        <div className="hero">
            <TranslatedObject translationKey="carousel">
                {({ images, logo }) => (
                    <>
                        <div className="hero__logo-box">
                            <img
                                className="hero__logo"
                                src={`/carousel/${logo}`}
                                alt="Logo Pumpernickel Films"
                            />
                        </div>
                        <div className="hero__carousel">
                            <Carousel effect="fade" dots={false} autoplay>
                                {images.map(image => (
                                    <div key={image.poster} className="hero__img-box">
                                        <div
                                            style={{
                                                backgroundImage: `url("/carousel/${image.poster}")`,
                                                backgroundRepeat: "no-repeat",
                                                backgroundSize: "cover",
                                                backgroundPosition: "center",
                                                height: "100%",
                                                width: "100%",
                                            }}
                                        />
                                    </div>
                                ))}
                            </Carousel>
                        </div>
                    </>
                )}
            </TranslatedObject>
        </div>
    )
}


export default Hero;