
import React from "react";
import { useTranslation } from "react-i18next";


function TranslatedObject({ children, translationKey }) {
    const { t } = useTranslation();

    return (
        <React.Fragment>
            {children(t(translationKey))}
        </React.Fragment>
    )
}


export default TranslatedObject;