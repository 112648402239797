
import React from "react";
import "./translationSwitch.css";


function TranslationSwitch({ languages, handleLanguageChoice }) {
    return (
        <ul className="translation-switch">
            {languages.map(({ value, icon }) => (
                <li
                    key={value}
                    className="translation-switch__language"
                    onClick={() => handleLanguageChoice(value)}
                >
                    <img className="translation-switch__icon" src={icon} alt={value} />
                </li>
            ))}
        </ul>
    )
}


export default TranslationSwitch;